/**
 * Created by jeremie on 31/03/17.
 * ©2017 Sukoa AG <Interactive Technologies> http://www.sukoa.com
 */
import $ from 'jquery';

const editMode = function(){
    selectInPagesApp();
    fullscreenPreview();
    hideForwardMessage();
};

function hideForwardMessage(){
    $("#message-forward").find(".closer").click(function(){
        $(this).parent().fadeOut(200);
    });
}
function fullscreenPreview(){
    let url = window.location.href.split('?')[0];
    addSideBarAction("Fullscreen Preview","fullscreen-preview","icon-webpages-app",url);
}

function selectInPagesApp(){
    let parentLocation = window.top.location.href;
    let newLocation = parentLocation.replace("app:pages:detail","app:pages:browser");
    newLocation = newLocation.replace(":edit",":treeview");
    addSideBarAction("Select in Pages","pages-link","icon-appslauncher",newLocation,false);
}

function addSideBarAction(title,id,iconclass,url,isBlank){
    if(typeof isBlank === 'undefined'){
        isBlank = true;
    }
    let par = $(window.parent.document);

    let prevAction = par.find(".icon-switch-preview").first().closest(".v-actionbar-group");
    let customAction = prevAction.clone().attr('id', id);

    // remove old button
    par.find("#"+id).remove();
    customAction.find(".v-text").text(title);
    customAction.find(".v-icon").attr("class","v-icon "+iconclass);
    customAction.click( function() {
        if(isBlank){
            window.open(url, '_blank');
        }else{
            window.top.location.href = url;
        }
    });
    prevAction.after(customAction);
}


module.exports = editMode;
