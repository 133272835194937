import Lazy from './Lazy';
import Player from '@vimeo/player';

class LazyFrame extends Lazy{

    constructor(selector='.lazy-frame')   {
        super({
            targets: selector,
            loadFn: iframeWrapper => {
                const src = iframeWrapper.getAttribute('data-lazy');
                if(src) {
                    const iframe = document.createElement('iframe');
                    iframe.src = src;
                    iframe.setAttribute('frameborder', iframeWrapper.getAttribute('frameborder'));
                    iframe.setAttribute('allow', iframeWrapper.getAttribute('allow'));
                    iframe.setAttribute('allowfullscreen', 'allowfullscreen');

                    iframeWrapper.insertAdjacentElement("beforeend", iframe);
                    setTimeout(()=>{
                        if(src.indexOf("youtube") !== -1){
                            iframe.contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
                        } else if(src.indexOf("vimeo") !== -1){
                            let player = new Player(iframe);
                            player.play();
                        }
                    }, 700)
                }
            },
            threshold: 0.01,
            rootMargin: {top: 0, right: 0, left: 0, bottom: 0},
            persist: false,
            simLoadLimit: 1,
            simLoadDelay: 200,
        })
    }
}

export default LazyFrame;
