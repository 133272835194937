const tools = {

    each(list, fn) {
        if(list && fn) {
            for(let i = 0; i < list.length; i++) {
                fn(list[i]);
            }
        }
    },

    isEditMode() {return document.body.classList.contains('editmode')},


    doOnWindowResizeList: [],
    doOnScrollEndList: [],
    doOnScrollList: [],
    windowScrolListenerInitialised: false,
    windowResizeListenerInitialised: false,

    scrollTo(elt, destination, duration = 300, fb) {

        let start, distance, timeStart;

        function proceed() {
            start = elt === window ? (window.pageYOffset || window.scrollY) : elt.scrollTop;
            distance = destination - start;
            timeStart = null;
            requestAnimationFrame(loop);
        }

        function loop(time) {
            if (!timeStart) {
                timeStart = time;
            }

            let timeElapsed = time - timeStart;

            if (elt === window) {
                window.scrollTo(0, ease(timeElapsed, start, distance, duration))
            } else {
                elt.scrollTop = ease(timeElapsed, start, distance, duration);
            }

            if (timeElapsed < duration) {
                requestAnimationFrame(loop)
            } else {
                if (elt === window) {
                    window.scrollTo(0, destination)
                } else {
                    elt.scrollTop = destination;
                }
                timeStart = false;
                if (fb) {
                    fb(elt);
                }
            }
        }

        proceed();

        function ease(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b
        }
    },

    easeTo(start, target, action, duration = 300) {
        if (start && target && action) {
            let distance, timeStart;

            function proceed() {
                distance = target - start;
                timeStart = null;
                requestAnimationFrame(loop);
            }

            function loop(time) {
                if (!timeStart) {
                    timeStart = time;
                }
                let timeElapsed = time - timeStart;
                //todo: result rounding as param
                action(Math.round(ease(timeElapsed, start, distance, duration)));
                if (timeElapsed < duration) {
                    requestAnimationFrame(loop)
                } else {
                    action(target);
                    timeStart = false;
                }
            }

            proceed();

            function ease(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b
            }
        }
    },

    doOnWindowResize(action) {
        if (action && action.fn) {
            this.doOnWindowResizeList.push(action);
            if(!this.windowResizeListenerInitialised) {
                tools.windowResizeListener();
            }
        }
    },

    onWindowResizeActions() {
        tools.each(this.doOnWindowResizeList, action => {
            action.fn(action.arg);
        });
    },

    windowResizeListener() {
        let timeoutFn = null;
        window.onresize = (e) => {
            if(timeoutFn != null) {window.clearTimeout(timeoutFn);}
            timeoutFn = setTimeout(() => {
                tools.onWindowResizeActions();
            }, 100);
        };
        tools.windowResizeListenerInitialised = true;
    },

    doOnScroll(action, duringScroll, scrollEnd) {
        if (action && action.fn) {
            if(duringScroll) {
                this.doOnScrollList.push(action)
            }
            if(scrollEnd) {
                this.doOnScrollEndList.push(action);
            }
            if(!tools.windowScrolListenerInitialised) {
                tools.windowScrollListener();
            }
        }
    },

    onScrollActions() {
        tools.each(this.doOnScrollList, action => {
            action.fn(action.arg)
        });
    },

    onScrollEndActions() {
        tools.each(this.doOnScrollEndList, action => {
            action.fn(action.arg)
        });
    },

    windowScrollListener(fct) {
        let timeoutFn = null;
        window.addEventListener('scroll', (e) => {
            tools.onScrollActions();
            if(timeoutFn != null) {window.clearTimeout(timeoutFn);}
            timeoutFn = setTimeout(() => {
                tools.onScrollEndActions();
            }, 100);
        })
        tools.windowScrolListenerInitialised = true;
    },

    ajxGet(url, options) {
        tools.ajx('GET', url, options)
    },

    ajxPost(url, options) {
        tools.ajx('POST', url, options)
    },

    ajx(method, url, options) {
        let req = null;
        if (url) {
            req = new XMLHttpRequest();

            req.onreadystatechange = function () {

                if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                    if (options.fallback) {
                        options.fallback(req.response);
                    }
                }
            };

            let params = options.body;
            if(params) {
                req.open(method, url, true);
                req.setRequestHeader('Content-type', 'application/json');
            } else {
                if (options.formData && options.formData instanceof FormData) {
                    params = '';
                    params += params.indexOf('?') === -1 ? '?' : '&';
                    for (let entry of options.formData.entries()) {
                        params += entry[0] + '=' + entry[1] + '&';
                    }
                    params = params.substr(0, params.length - 1);
                }

                if (options.replaceHistory) {
                    tools.replaceHistoryState(url);
                }

                req.open(method, url, true);
                if(params) {
                    req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                }
            }
            req.responseType = options.responseType || 'text';
            req.send(params);
        } else if(options.fallback){
            options.fallback({error: 'url not provided'})
        }
        return req;
    }
};

module.exports = tools;
