import $ from 'jquery';
import Lazy from './Lazy';
const tools = require('./tools');
import 'whatwg-fetch'
import LazyFrame from "./lazy/LazyFrame";

const sonicspaceProject = {
    isTouchUser: false,
    isEditMode: false,
    isMobileNavInit: false,
    contentSelectors : "#brand-area, #title-area, #slider-area, #header-content, #content-area, #footer-area",

    init() {
        sukoaUtils.checkIfEditMode();
        if(sonicspaceProject.isEditMode) {
            require('./editmode/editmode.js').call();
        }
        window.onresize = function(){
            sonicspaceProject.mobileNavigation();
            sonicspaceProject.subNavPosition();
        };
        sonicspaceProject.mobileNavigation();
        sonicspaceProject.navigation();
        sonicspaceProject.subNavPosition();
        sonicspaceProject.expandableTextImageComponent();
        sonicspaceProject.expandableText();
        sonicspaceProject.eventListSwitch();
        sonicspaceProject.linkIcons();
        sonicspaceProject.moveFooterInEventsList();
        sonicspaceProject.footerSocialLinks();
        sonicspaceProject.cookiesAlert();
        sonicspaceProject.goTop();
        sonicspaceProject.homeComponent();

        sonicspaceProject.videos();
        sonicspaceProject.initCalendarWiz();
    },

    mobileNavigation(){
        let navigationElement = document.getElementById("navigation");

        if (sukoaUtils.checkIfMobileScreenSize() && !sonicspaceProject.isMobileNavInit){
            let openNavigationLink = document.getElementById("open_navigation");

            let html = document.documentElement;

            if (openNavigationLink){
                openNavigationLink.addEventListener("click", function () {
                    if (openNavigationLink.classList.contains("open")) {
                        openNavigationLink.classList.remove('open');
                        navigationElement.classList.remove('open');
                        html.classList.remove('fix-content');
                    } else {
                        openNavigationLink.classList.add('open');
                        navigationElement.classList.add('open');
                        navigationElement.classList.add('open');
                        html.classList.add('fix-content');
                    }
                });
            }

            sonicspaceProject.isMobileNavInit = true;
        }
    },

    navigation(){
        let navigationElement = document.getElementById("navigation");
        let navigationItems = navigationElement.getElementsByClassName("hasSublevels");
        Array.prototype.filter.call(navigationItems, function(navigationItem){
            if (!navigationItem.parentElement.classList.contains("first-sub-nav") || sukoaUtils.checkIfMobileScreenSize()){
                let navigationItemLinks = $(navigationItem).find("> a");
                Array.prototype.filter.call(navigationItemLinks, function(navigationItemLink){
                    $(navigationItemLink).click(function(e){
                        e.preventDefault();
                        let el = $(navigationItemLink).closest("li")[0];
                        if (el.classList.contains("active")){
                            el.classList.remove("active");
                        } else {
                            el.classList.add("active");
                        }
                    });
                });
            }
        });
    },

    subNavPosition(){
        if (!sukoaUtils.checkIfMobileScreenSize()){
            let navigationElement = document.getElementById("navigation");
            let firstSubNav = navigationElement.getElementsByClassName("first-sub-nav")[0];
            $(firstSubNav).find("> li").each(function () {
                let el = $(this);
                let lp = el[0].offsetLeft;
                $(el).find("> ul > li").each(function () {
                    let ul = $(this);
                    ul[0].style.left = lp + "px";
                });
            });
        }
    },

    homeComponent(){
        let backgroundVideo = document.getElementById('background_video');

        if (backgroundVideo){
            backgroundVideo.play();
            let soundButton = document.getElementById('sound_button');
            let muteButton = document.getElementById('mute_button');

            soundButton.addEventListener("click", function () {
                backgroundVideo.muted = true;
                soundButton.classList.add("hide");
                muteButton.classList.remove("hide");
            });
            muteButton.addEventListener("click", function () {
                backgroundVideo.muted = false;
                muteButton.classList.add("hide");
                soundButton.classList.remove("hide");
            });
        }

        let scrollNextButton = document.getElementById('home_go_next');
        let main = document.getElementById('main-column');
        let hc = $(main).find('.home-component')[0];
        if (scrollNextButton){
            scrollNextButton.addEventListener("click", function () {
                let homeSecondWrapper = $('.home-second-wrapper');
                let sp = homeSecondWrapper[0].offsetTop;
                if (sukoaUtils.checkIfMobileScreenSize()) {
                    let hcp = hc.offsetTop;
                    window.scroll({
                        top: sp + hcp,
                        behavior: 'smooth'
                    });
                } else {
                    hc.scroll({
                        top: sp,
                        behavior: 'smooth'
                    });
                }
            });
        }
    },

    expandableTextImageComponent(){
        let expandableTextImageElements = document.getElementsByClassName("expandable-text-image-component with-exp-content");

        let secondColumn = document.getElementById("second-column");
        let mainColumn = document.getElementById("main-column");
        let mainWrapper = document.getElementById("main_wrapper");


        Array.prototype.filter.call(expandableTextImageElements, function(expandableTextImageElement){

            let expandableLink = expandableTextImageElement.getElementsByClassName("expandable-link");
            if (expandableTextImageElement.classList.contains("show-detail")){
                const scrollToPosition = () => {
                    if(sukoaUtils.checkIfMobileScreenSize()) {
                        tools.scrollTo(window, expandableTextImageElement.offsetTop, 0)
                    } else {
                        tools.scrollTo(mainColumn, expandableTextImageElement.offsetTop - 30, 0)
                    }
                }

                let imagesElt = mainColumn.querySelectorAll('img, image');
                for(let i = 0; i < imagesElt.length; i++ ) {
                    let img = imagesElt[i];
                    img.onload = ()=>setTimeout(scrollToPosition, 300);
                }
                expandableTextImageElement.classList.remove("show-detail");
                expandableTextImageElement.classList.add("show-exp");
                mainColumn.classList.add("show-exp");
                if (!sukoaUtils.checkIfMobileScreenSize()){
                    let scHeight = mainWrapper.offsetHeight;
                    let scTop = secondColumn.offsetTop + mainWrapper.offsetTop;
                    let expElement = expandableTextImageElement.getElementsByClassName("expandable-wrapper")[0];
                    expElement.style.top = scTop+16+"px";
                    expElement.style.height = scHeight+"px";
                }
                scrollToPosition();
                setTimeout(scrollToPosition, 2000);
            }
            $(expandableLink).click(function(){

                if (expandableTextImageElement.classList.contains("show-exp")){
                    expandableTextImageElement.classList.remove("show-exp");
                    mainColumn.classList.remove("show-exp");
                    secondColumn.style.height = "auto";
                    let baseUrl = this.dataset.baseUrl;
                    if (baseUrl){
                        history.replaceState({}, '', baseUrl);
                    }
                } else {
                    let expandableTextImageOpenElements = document.getElementsByClassName("expandable-text-image-component show-exp");
                    let detailUrl = this.dataset.detailUrl;
                    let detailTitle = this.dataset.detailTitle;
                    if (detailUrl){
                        history.replaceState({}, detailTitle, detailUrl);
                    }
                    if (!sukoaUtils.checkIfMobileScreenSize()) {
                        Array.prototype.filter.call(expandableTextImageOpenElements, function (expandableTextImageOpenElement) {
                            expandableTextImageOpenElement.classList.remove("show-exp");
                        });
                    }
                    expandableTextImageElement.classList.add("show-exp");
                    mainColumn.classList.add("show-exp");
                    if (!sukoaUtils.checkIfMobileScreenSize()){
                        let scHeight = mainWrapper.offsetHeight;
                        let scTop = secondColumn.offsetTop + mainWrapper.offsetTop;
                        let expElement = expandableTextImageElement.getElementsByClassName("expandable-wrapper")[0];
                        expElement.style.top = scTop+16+"px";
                        expElement.style.height = scHeight+"px";
                    }
                }
            });
        });
    },

    expandableText(){
        let listContentElements = document.getElementsByClassName("list-item");
        if (listContentElements){
            Array.prototype.filter.call(listContentElements, function(listContentElement){
                const scrollToPosition = () => {
                    if(sukoaUtils.checkIfMobileScreenSize()) {
                        tools.scrollTo(window, listContentElement.offsetTop, 0);
                    } else {
                        tools.scrollTo(listContentElement.parentElement.parentElement, listContentElement.offsetTop - 51, 0)
                    }
                }

                if (listContentElement.classList.contains("active-detail")){
                    listContentElement.classList.remove("active-detail");
                    scrollToPosition();
                    setTimeout(scrollToPosition, 1000);
                }
                let expandableLinks = listContentElement.getElementsByClassName("expandable-link");
                let expandableTextElements = listContentElement.getElementsByClassName("expandable-wrapper");
                Array.prototype.filter.call(expandableLinks, function(expandableLink){
                    $(expandableLink).click(function(e){

                        if(e.target.tagName.toLowerCase() === 'a' ) {
                            return true;
                        }

                        let elt = this;
                        Array.prototype.filter.call(expandableTextElements, function(expandableTextElement){
                            if (expandableTextElement){
                                if (expandableTextElement.classList.contains("close")){
                                    expandableTextElement.classList.add("open");
                                    expandableTextElement.classList.remove("close");
                                    let detailUrl = elt.dataset.detailUrl;
                                    let detailTitle = elt.dataset.detailTitle;

                                    if (detailUrl){
                                        history.replaceState({}, detailTitle, detailUrl);
                                    }
                                } else if (expandableTextElement.classList.contains("open")){
                                    expandableTextElement.classList.add("close");
                                    expandableTextElement.classList.remove("open");
                                    let baseUrl = elt.dataset.baseUrl;
                                    if (baseUrl){
                                        history.replaceState({}, '', baseUrl);
                                    }
                                }
                            }
                        });
                    });
                });
            });
        }
    },

    linkIcons(){
        let mainWrapper = document.getElementById("main-column-wrapper");
        let linkElements = mainWrapper.getElementsByTagName("a");
        if (linkElements){
            initIcons(linkElements);
        }

        let secondColumnLinkElements = document.getElementsByTagName("#second-column a");
        if (secondColumnLinkElements){
            initIcons(secondColumnLinkElements);
        }

        function initIcons(linkElements){
            Array.prototype.filter.call(linkElements, function(linkElement){
                let link = linkElement.href;
                if (link.indexOf("/dam/jcr") > -1){
                    linkElement.innerHTML += linkElement.innerHTML.indexOf("↓") > -1? "" : " ↓";
                } else if (linkElement.target != null && linkElement.target == "_blank"){
                    linkElement.innerHTML += linkElement.innerHTML.indexOf("↗") > -1? "" : " ↗";
                } else if (!linkElement.classList.contains("title-link") && !link.indexOf("mailto") > -1) {
                    linkElement.innerHTML += linkElement.innerHTML.indexOf("↘") > -1? "" : " ↘";
                }

                linkElement.addEventListener('click', e => e.stopPropagation())
            });
        }
    },

    footerSocialLinks(){
        let socialLinksWrapper = document.getElementById("social_links_wrapper");
        let iconWrapper = document.getElementById("footer_social_links_icon_wrapper");
        if (iconWrapper){
            iconWrapper.addEventListener("click", function () {
                if (sukoaUtils.checkIfMobileScreenSize()){
                    if (socialLinksWrapper.classList.contains("open")){
                        socialLinksWrapper.classList.remove("open");
                    } else {
                        socialLinksWrapper.classList.add("open");
                    }
                }
            });
        }
    },

    moveFooterInEventsList() {
        const footer = document.querySelector('footer'); //make sure only 1 <footer> in the page
        const eventsList = document.querySelector('#events_list');
        if(footer && eventsList) {
            eventsList.insertAdjacentElement('beforeend', footer);
        }

    },

    eventListSwitch(){
        let eventsTitleElement = document.getElementById("events-news-title-wrapper");

        if (eventsTitleElement){
            let expandableLinks = eventsTitleElement.getElementsByClassName("title-link");
            Array.prototype.filter.call(expandableLinks, function(expandableLink){
                $(expandableLink).click(function(e){
                    e.preventDefault();
                    if (!expandableLink.classList.contains("active") && (!expandableLink.classList.contains("mobile-only") || sukoaUtils.checkIfMobileScreenSize())){
                        let activeElements = eventsTitleElement.getElementsByClassName("title-link active");
                        Array.prototype.filter.call(activeElements, function(activeElement){
                            activeElement.classList.remove("active");
                            let activeTarget = activeElement.href;
                            activeTarget = activeTarget.split("#")[1];
                            let activeTargetElement = document.getElementById(activeTarget);
                            if (activeTargetElement){
                                if (activeTargetElement.classList.contains("medium-hide")){
                                    activeTargetElement.classList.remove("show-medium-hide");
                                } else{
                                    activeTargetElement.classList.add("close");
                                }
                            }
                        });

                        expandableLink.classList.add("active");
                        let target = expandableLink.href;
                        target = target.split("#")[1];
                        let targetElement = document.getElementById(target);
                        if (targetElement){
                            if (targetElement.classList.contains("medium-hide")){
                                targetElement.classList.add("show-medium-hide");
                            } else{
                                targetElement.classList.remove("close");
                            }
                        }
                    }
                });
            });
        }
    },

    cookiesAlert() {

        let cookieName = 'sonic-space-cookies-accepted=true';
        let cookieAlertElt = document.getElementById('cookie_alert');
        let footer = document.getElementsByTagName('footer')[0];
        if (cookieAlertElt) {

            if (document.cookie.indexOf(cookieName) === -1) {
                cookieAlertElt.classList.remove('accepted');
                footer.style.paddingBottom = cookieAlertElt.offsetHeight + "px";

                window.onresize = function(){
                    footer.style.paddingBottom = cookieAlertElt.offsetHeight + "px";
                };
            }

            let cookieBtn = cookieAlertElt.getElementsByClassName('ca-accept')[0];

            cookieBtn.addEventListener('click', evt => {
                let d = new Date();
                d.setTime(d.getTime() + (360 * 24 * 60 * 60 * 1000));
                document.cookie = cookieName + ";expires=" + d.toUTCString() + ";path=/";
                footer.style.paddingBottom = "0px";
                cookieAlertElt.classList.add('accepted');
            });
        }

    },

    initCalendarWiz() {
        function initCalendarWizLoginTabs() {
            $(".tablinks").on("click", evt => {
                let tabname = evt.currentTarget.dataset.tab;
                // Declare all variables
                let i, tabcontent, tablinks;

                // Get all elements with class="tabcontent" and hide them
                tabcontent = document.getElementsByClassName("tabcontent");
                for (i = 0; i < tabcontent.length; i++) {
                    tabcontent[i].style.display = "none";
                }

                // Get all elements with class="tablinks" and remove the class "active"
                tablinks = document.getElementsByClassName("tablinks");
                for (i = 0; i < tablinks.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" active", "");
                }

                // Show the current tab, and add an "active" class to the button that opened the tab
                document.getElementById(tabname).style.display = "block";
                evt.currentTarget.className += " active";
            })
        }

        function createIFrame(login, pwd) {
            let calendarWizIntegrationElement = document.getElementById("calendarwiz-integration");

            let htmliFrameElement = document.createElement("iframe");

            if (sukoaUtils.checkIfMobileScreenSize()) {
                htmliFrameElement.src = "https://www.calendarwiz.com/mobile.html?crd=sonicspacebasel&cid[]=all&nolognavbar=1" + (pwd!==null ? "&scr=" + login + "&psw=" + pwd : "");
                htmliFrameElement.height = "100%"
            } else {
                htmliFrameElement.src = "https://www.calendarwiz.com/calendars/week.php?crd=sonicspacebasel&cid[]=all&nolog=1&skiptitle=1" + (pwd!==null ? "&scr=" + login + "&psw=" + pwd : "");
                // htmliFrameElement.src = "https://www.calendarwiz.com/calendars/week.php?crd=sonicspacebasel&cid[]=all" + (pwd!==null ? "&scr=" + login + "&psw=" + pwd : "");
                htmliFrameElement.height = "100%"
            }

            $("#calendarwiz-integration .logout").on("click", evt => {
                evt.preventDefault();
                document.cookie = "sonic-space-calenderwiz=false ; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=/"

                htmliFrameElement.src = evt.target.href
                setTimeout(function() {
                    window.location.reload()
                }, 500)

            })

            htmliFrameElement.width = "100%";
            calendarWizIntegrationElement.appendChild(htmliFrameElement);
            calendarWizIntegrationElement.classList.remove("hide")

        }

        function hideTextComponents() {
            sukoaUtils.checkIfEditMode();
            if (!sonicspaceProject.isEditMode) {
                //Hide other text components
                let textImageComponent = document.getElementsByClassName("simple-text-image-component");
                for (let i = 0; i < textImageComponent.length; i++) {
                    textImageComponent[i].classList.add("hide")
                }
            }
        }

        if (document.getElementsByClassName("calendar-component") !== undefined && document.getElementsByClassName("calendar-component").length > 0) {
            initCalendarWizLoginTabs();

            let calendarWizLoginElement = document.getElementById("calendarwiz-login");

            if (document.cookie.indexOf("sonic-space-calenderwiz") !== -1) {
                //Show calendar
                createIFrame(null, null);
                hideTextComponents();

            } else {
                //Show login
                calendarWizLoginElement.classList.remove("hide");

                let loginForms = document.getElementsByClassName("calendarwiz-login-form");
                for (let i = 0; i < loginForms.length; i++) {
                    loginForms[i].addEventListener("submit",  e => {
                        e.preventDefault();

                        let _form = e.currentTarget;

                        let login;
                        let pwd = _form.getElementsByClassName("calendarwiz-pwd")[0].value;


                        if (_form.getElementsByClassName("calendarwiz-user")[0] !== undefined) {
                            login = _form.getElementsByClassName("calendarwiz-user")[0].value;

                            createIFrame(login, pwd);
                            document.cookie = "sonic-space-calenderwiz=true;;path=/";
                            calendarWizLoginElement.classList.add("hide");

                            hideTextComponents();
                        } else {
                            login = _form.dataset.calendarwizReaduser

                            window.fetch(_form.action + "?cmd=validatePassword&pw=" + pwd)
                                .then(response => {
                                    if (response.status >= 200 && response.status < 300) {
                                        createIFrame(login, pwd);
                                        document.cookie = "sonic-space-calenderwiz=true;;path=/";
                                        calendarWizLoginElement.classList.add("hide");

                                        hideTextComponents();
                                    } else {
                                        _form.getElementsByClassName("pwd-hint")[0].classList.add("show");
                                    }
                                })
                        }

                    });
                }
            }
        }
    },

    goTop(){
        let goTopButton = document.getElementById('go_top');

        if (goTopButton){
            goTopButton.addEventListener("click", function () {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            });
        }
    },

    videos() {

        const videoWrappers = document.querySelectorAll('.video-wrapper.poster');

        const btnClickListener = (e, wrapper, iframeWrapper, btn) => {
            btn.classList.add('rotate');
            new LazyFrame(iframeWrapper);
            setTimeout(() => {
                wrapper.classList.add('video-init');
                btn.classList.remove('rotate');
            }, 700);
        }

        const wrapperInitFn = wrapper => {
            if (wrapper.classList.contains('vimeo-no-img')){
                const videoId = wrapper.dataset.videoId;
                if (videoId){
                    const poster = wrapper.querySelector('.video-poster');
                    const link = 'https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + videoId + '&format=json';

                    fetch(link).then(res => res.json()).then(out => {
                        let thumbnail_url = out.thumbnail_url;
                        if (thumbnail_url){
                                thumbnail_url = thumbnail_url.replace("-d_295x166", "-d_640");
                                poster.style.backgroundImage = "url('"+thumbnail_url+"')";
                            }
                        })
                }
            }
            const btn = wrapper.querySelector('.play-btn');
            const iframeWrapper = wrapper.querySelector('.sizer');

            if(btn && iframeWrapper) {
                btn.addEventListener('click', e => btnClickListener(e, wrapper, iframeWrapper, btn))
            }
        }

        tools.each(videoWrappers, wrapperInitFn)
    }
};



const sukoaUtils = {
    checkIfMobileScreenSize(){
        return window.innerWidth <= 1024;
    },

    checkIfEditMode() {
        sonicspaceProject.isEditMode = $('body').hasClass('editmode');
    },

    dcmadr(nnnn) {
        let a = "";
        for (let i = 0, m = nnnn.length; i < m; i++) {
            if (i % 3 === 0) {
                a += String.fromCharCode(nnnn.substr(i, 3));
            }
        }
        location.href = (a);
    },

    findParentBySelector(elm, selector) {
    let all = document.querySelectorAll(selector);
        let cur = elm.parentNode;
        while(cur && !sukoaUtils.collectionHas(all, cur)) { //keep going up until you find a match
            cur = cur.parentNode; //go up
        }
        return cur; //will return null if not found
    },

    collectionHas(a, b) { //helper function (see below)
        for(let i = 0, len = a.length; i < len; i ++) {
            if(a[i] == b) return true;
        }
        return false;
    },


};

window.sukoaUtils = {dcmadr : sukoaUtils.dcmadr};

$(document).ready(function() {
    sonicspaceProject.init();
});
